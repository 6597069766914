import { logout } from '../api/axios';
import useAuth from './useAuth';

const useLogout = () => {

  const { setAuth } = useAuth();

  // LE PONGO ESTE NOMBRE DE MIERDA PARA DEJAR LIBRE EL NOMBRE logout
  // QUE ES EL DE LA API
  const useLogoutLogout = async () => {

    setAuth({});

    try {

      await logout();

    } catch (err) {

      console.log(err);

    }

  }

  return useLogoutLogout;

}

export default useLogout;