import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: API_URL
});

export const axiosPrivate = axios.create({
  baseURL: API_URL,
  headers: { 'Content-Type': 'text/plain' },
  withCredentials: true
})



const instance = axios.create({

  baseURL: API_URL,

})

export const getCampaigns = async (params) => {

  const { props, auth } = params;

  const headers = {
    'Authorization': `Bearer ${ auth?.accessToken }`
  }

  const { data } = await instance.get(`campaigns`, { ...props, headers } );
  return data;

};

export const getBrands = async (params) => {

  const { props, auth } = params;
  
  const headers = {
    'Authorization': `Bearer ${auth?.accessToken}`
  }

  const { data } = await instance.get(`brands`, { ...props, headers } );
  return data;
};

export const getConsents = async (params) => {

  const { props, auth } = params;
  
  const headers = {
    'Authorization': `Bearer ${auth?.accessToken}`
  }

  const { data } = await instance.get(`consents`, { ...props, headers } );
  return data;
};

export const getUsers = async (params) => {

  const { props, auth } = params;
  
  const headers = {
    'Authorization': `Bearer ${auth?.accessToken}`
  }

  const { data } = await instance.get(`users`, { ...props, headers } );
  return data;
};

export const getContactTypes = async (params) => {

  const { props, auth } = params;
  
  const headers = {
    'Authorization': `Bearer ${auth?.accessToken}`
  }

  const { data } = await instance.get(`contact-types`, { ...props, headers } );
  return data;
};

export const getProfiles = async (params) => {

  const { props, auth } = params;
  
  const headers = {
    'Authorization': `Bearer ${auth?.accessToken}`
  }

  const { data } = await instance.get(`profiles`, { ...props, headers } );
  return data;
};

export const getContactsList = async (contact_list_id, auth) => {

  const headers = {
    'Authorization': `Bearer ${auth?.accessToken}`
  }

  const { data } = await instance.get(`contacts-list/${contact_list_id}`, { headers });
  return data;
};



export const getUser = async (user_id, auth) => {
  
  const headers = {
    'Authorization': `Bearer ${auth?.accessToken}`
  }

  const { data } = await instance.get(`user/${user_id}`, { headers });
  return data;
};


export const insertUser = async (
  auth, 
  id, 
  name, 
  last_name, 
  email, 
  company, 
  profile_id, 
  cell_phone
  ) => {

    const headers = {
      'Authorization': `Bearer ${auth?.accessToken}`
    }  

  const { data } = await instance.post(`user`,

  JSON.stringify({
    id, 
    name, 
    last_name, 
    email, 
    company, 
    profile_id, 
    cell_phone
  }), { headers });
  
  return data;

}

export const getLists = async (params) => {

  const { props, auth } = params;
  
  const headers = {
    'Authorization': `Bearer ${auth?.accessToken}`
  }

  const { data } = await instance.get(`contacts-lists`, { ...props, headers } );
  return data;
};
  
  
  
export const getJourneys = async (params) => {

  const { props, auth } = params;
  
  const headers = {
    'Authorization': `Bearer ${auth?.accessToken}`
  }

  const { data } = await instance.get(`journeys`, { ...props, headers } );
  return data;
  
};
  
  
export const insertContactsList = async (
  auth, 
  id, 
  brand_id, 
  name,
  contact_type_id, 
  ) => {

  const headers = {
    'Authorization': `Bearer ${auth?.accessToken}`
  }

  const { data } = await instance.post(`contacts-list`,

  JSON.stringify({
    id, 
    brand_id, 
    name,
    contact_type_id, 
  }), { headers });
  
return data;

}
  
export const insertCampaign = async (
  auth,
  id, 
  brand_id, 
  name, 
  year
  ) => {

  const headers = {
    'Authorization': `Bearer ${auth?.accessToken}`
  }

  const { data } = await instance.post(`campaign`,

  JSON.stringify({
    id, 
    brand_id, 
    name, 
    year
  }), { headers });
  
return data;

}

export const getCampaign = async (campaign_id, auth) => {

  const headers = {
    'Authorization': `Bearer ${auth?.accessToken}`
  }

  const { data } = await instance.get(`campaign/${campaign_id}`, { headers });
  return data;
};

export const getCampaignYears = async (params) => {

  const { auth } = params;
  
  const headers = {
    'Authorization': `Bearer ${ auth?.accessToken }`
  }
  
  const { data } = await instance.get(`campaign-years`, { headers });
  return data;
};



export const sendTestEmail = async (auth, id, test_emails) => {

  const headers = {
    'Authorization': `Bearer ${auth?.accessToken}`
  }

  const { data } = await instance.post(`send-test-email`,

  JSON.stringify({ 
    id, 
    test_emails,
  }), { headers });
  
return data;

}

export const activateEmail = async (

  id, 
  active,

) => {

  const { data } = await instance.post(`email-activate`,

  JSON.stringify({ 
    id, 
    active,
  }));
  
return data;

}

export const insertEmail = async (
  auth,
  id, 
  active,
  brand_id, 
  campaign_id,
  name, 
  subject,
  sender_name,
  sender_email,
  reply_to,
  date_sent_from,
  date_sent_until,
  test_emails,
  contact_list_id,
  journey_id,
  html,
  resend_days,
  resend_subject
  ) => {

  const headers = {
    'Authorization': `Bearer ${auth?.accessToken}`
  }
  
  const { data } = await instance.post(`email`,

  JSON.stringify({ 
    id, 
    active,
    brand_id, 
    campaign_id,
    name, 
    subject,
    sender_name,
    sender_email,
    reply_to,
    date_sent_from,
    date_sent_until,
    test_emails,
    contact_list_id,
    journey_id,
    html,
    resend_days,
    resend_subject
  }), { headers });
  
return data;

}
  
export const getEmails = async (params) => {

  const { props, auth } = params;
  
  const headers = {
    'Authorization': `Bearer ${auth?.accessToken}`
  }

  const { data } = await instance.get(`emails`, { ...props, headers } );
  return data;
};

export const getEmail = async (email_id, auth) => {

  const headers = {
    'Authorization': `Bearer ${auth?.accessToken}`
  }

  const { data } = await instance.get(`email/${email_id}`, { headers });

  return data;
};

export const getEmailContacts = async (params) => {
 
  const { idEmail: email_id, props, auth } = params;

  const headers = {
    'Authorization': `Bearer ${auth?.accessToken}`
  }

  console.log(email_id);

  console.log(`email-contacts/${email_id}`)

  const { data } = await instance.get(`email-contacts/${email_id}`,  { ...props, headers });
  return data;
  
};

export const getContacts = async (params) => {

  const { props, auth } = params;
  
  const headers = {
    'Authorization': `Bearer ${auth?.accessToken}`
  }

  const { data } = await instance.get(`contacts`, { ...props, headers } );
  return data;
};

export const login = async (email, password) => {

  const { data } = await instance.post(`login`, JSON.stringify({email, password}));
    
  return data;

}

export const checkOTP = async (otp) => {

  const { data } = await instance.post(`check-otp`, JSON.stringify({otp}));
    
  return data;

}

export const passwordRecovery = async (email) => {

  const { data } = await instance.post(`user-password-recovery`, JSON.stringify({email}));
    
  return data;

}

export const passwordReset = async (hash, password) => {

  const { data } = await instance.post(`user-password-reset`, JSON.stringify({hash, password}));
    
  return data;

}


export const logout = async () => {

  const { data } = await instance.post(`logout`);
    
  return data;

}

export const refresh = async () => {

  const { data } = await instance.post(`refresh`);
    
  return data;

}

