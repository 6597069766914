import { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom"
import useAuth from "../../hooks/useAuth";

import { login, checkOTP } from "../../api/axios"
import Logo from "../../components/Logo";

const Login = () => {
  
  const { setAuth }  = useAuth();
  
  const emailRef = useRef();
  const otpRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
 

  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const [ displayFormLogin, setDisplayFormLogin ] = useState(true);
  const [ displayFormOtp, setDisplayFormOtp ] = useState(false);

  useEffect(()=>{

    emailRef.current.focus();

  },[])

  useEffect(()=>{

    setError(null);

  },[email,password])

  const handleSubmitFormLogin = async (e) => {

    e.preventDefault();
    
    try {
      
      const data = await login(email, password);

      if(data?.status !== 'ok') {

        setError(data.message);
        return;

      }

      const accessToken = data?.results?.accessToken;
      const roles = data?.results?.roles;
      
      setAuth({ roles, accessToken });

      setEmail('');
      setPassword('');
      setError(null);

      setDisplayFormLogin(false);
      setDisplayFormOtp(true);

    } catch (error) {

      if (!error?.response) {
        setError('No Server Response');
      } else if (error.response?.status === 400) {
        setError('Missing Username or Password');
      } else if (error.response?.status === 401) {
        setError('Unauthorized');
      } else {
        setError('Login Failed');
      }
      
    }

  }
  
  const handleSubmitFormOtp = async (e) => {

    e.preventDefault();
    
    try {
      
      const data = await checkOTP(otp);

      if(data?.status !== 'ok') {

        setError(data.message);
        return;

      }

      setError(null);

      navigate(from, { replace: true });

    } catch (error) {

      if (!error?.response) {
        setError('No Server Response');
      } else if (error.response?.status === 400) {
        setError('Missing Username or Password');
      } else if (error.response?.status === 401) {
        setError('Unauthorized');
      } else {
        setError('Login Failed');
      }
      
    }

  }

  return (
    
        <div className="content white">

            <Logo />


            { displayFormLogin &&

            <form className="form login" onSubmit={handleSubmitFormLogin}>

              <h2 className="title">Marketing Automation Tool</h2>

                <div className="field">
                    <label htmlFor="email">Email</label>
                    <input 
                      ref={emailRef}
                      type="text" 
                      id="email" 
                      autoComplete="off"  
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                    />
                </div>

                <div className="field">
                    <label htmlFor="password">Contraseña</label>
                    <input 
                      type="password" 
                      id="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password} 
                      required
                    />
                </div>

                <p className="center mb--30"><NavLink to="/password-recovery" className="underline">¿Olvidaste tu contraseña?</NavLink></p>

                <p className={error ? "errmsg" : "hidden"}>{error}</p>

                <div className="buttons">

                    <button className="button">Ingresar</button>

                </div>

            </form> 
            
            }

            { displayFormOtp &&

            <form className="form login" onSubmit={handleSubmitFormOtp}>

              <h2 className="title">Marketing Automation Tool</h2>

              <p className="mb--30">Enviamos un código de acceso temporal a tu casilla de email.</p>

                <div className="field">
                    <label htmlFor="otp">Código</label>
                    <input 
                      ref={otpRef}
                      type="text" 
                      id="otp" 
                      autoComplete="off"  
                      onChange={(e) => setOtp(e.target.value)}
                      value={otp}
                      required
                    />
                </div>

                <p className={error ? "errmsg" : "hidden"}>{error}</p>

                <div className="buttons">

                    <button className="button">Ingresar</button>

                </div>

            </form>

            }

        </div>
   
    )
}

export default Login