import { NavLink, useNavigate } from "react-router-dom";
import useLogout from "../hooks/useLogout";

const Navbar = () => {

    const navigate = useNavigate();
    const logout = useLogout();

    const signOut = async () => {
        await logout();
        navigate('/login');
    }

    return (
        
        <nav>
            <ul className="main">
                <li className="main">
                    <NavLink className={({ isActive }) => (isActive ? "active" : "")} to="/dashboard">Dashboard</NavLink>

                    <div className="float_overlay">
                        <div className="float">
                            <div className="twocols">

                                <div className="colone">
                                    <h3 className="mb-20">Dashboard</h3>
                                    <ul>
                                        <li>
                                            <NavLink to="/dashboard">Principal</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/dashboard/contacts">Contactos</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/dashboard/pet-owners">Dueños de mascotas</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/dashboard/pets">Mascotas</NavLink>
                                        </li>
                                    </ul>
                                    
                                </div>
                                {/* <div className="coltwo">
                                    <h3 className="mb-20">Campañas</h3>
                                    <ul>
                                        <li>
                                            <NavLink to="/dashboard/campaigns">Todas</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/dashboard/campaigns/start-of-life">Start of Life</NavLink>
                                        </li>
                                    </ul>
                                </div> */}

                            </div>
                        </div>
                    </div>

                </li>
                <li className="main">
                    <NavLink className={({ isActive }) => (isActive ? "active" : "")} to="/emails">Campañas</NavLink>
                    
                    <div className="float_overlay">
                        <div className="float">
                            <div className="twocols">

                                <div className="colone">
                                    <h3 className="mb-20">Campañas</h3>
                                    <ul>
                                        <li>
                                            <NavLink to="/campaigns">Listado de campañas</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/campaigns/edit">Alta de campaña</NavLink>
                                        </li>
                                    </ul>
                                    
                                </div>
                                <div className="coltwo">
                                    <h3 className="mb-20">Envíos</h3>
                                    <ul>
                                        <li>
                                            <NavLink to="/emails">Listado de envíos</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/emails/edit">Alta de envío</NavLink>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </li>
                <li className="main">
                    <NavLink className={({ isActive }) => (isActive ? "active" : "")} to="/contacts">Destinatarios</NavLink>
                    <div className="float_overlay">
                        <div className="float">
                            <div className="twocols">

                                <div className="colone">
                                    <h3 className="mb-20">Destinatarios</h3>
                                    <ul>
                                        <li>
                                            <NavLink to="/contacts">Listado de destinatarios</NavLink>
                                        </li>
                                        {/* <li>
                                            <a href="">Alta de destinatario</a>
                                        </li> */}
                                    </ul>
                                    
                                </div>
                                <div className="coltwo">
                                    <h3 className="mb-20">Listas de destinatarios</h3>
                                    <ul>
                                        <li>
                                            <NavLink to="/contacts-lists">Listas de destinatarios</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/contacts-lists/edit">Alta de Lista de destinatarios</NavLink>
                                            <a target="_blank" href="http://www.miroyalcanin.chali.thet.com.ar/miroyalcanin.com.ar/nuevo-ddm/crm_lista_alta.php">Alta de lista de destinatarios HTML</a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="http://www.miroyalcanin.chali.thet.com.ar/miroyalcanin.com.ar/nuevo-ddm/crm_lista_importacion.php">Importación de destinatarios HTML</a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </li>
                <li className="main">
                    <NavLink className={({ isActive }) => (isActive ? "active" : "")} to="/journeys">Journeys</NavLink>
                    <div className="float_overlay">
                        <div className="float">
                            <div className="twocols">

                                <div className="colone">
                                    <h3 className="mb-20">Journeys</h3>
                                    <ul>
                                        <li>
                                            <NavLink to="/journeys">Listado de Journeys</NavLink>
                                        </li>
                                    </ul>
                                    
                                </div>
                                <div className="coltwo">
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </li>
                <li className="main">
                    <NavLink className={({ isActive }) => (isActive ? "active" : "")} to="/users">Administración</NavLink>
                    <div className="float_overlay">
                        <div className="float">
                            <div className="twocols">

                                <div className="colone">
                                    <h3 className="mb-20">Usuarios</h3>
                                    <ul>
                                        <li>
                                            <NavLink to="/users">Listado de Usuarios</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/users/edit">Alta de Usuarios</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#" onClick={signOut}>Logout</NavLink>
                                        </li>
                                    </ul>
                                    
                                </div>
                                <div className="coltwo">
                                    <h3 className="mb-20">Configuración</h3>
                                    <ul>
                                        <li>
                                            <NavLink to="/data-sources">Fuentes de datos</NavLink>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </nav>
    
    
    )
}

export default Navbar