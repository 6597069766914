import { useRef, useState, useEffect } from "react";
import { getBrands, getContactTypes, getConsents } from "../../api/axios"
import { validateDate } from "../../utilities/Utils"
import useAuth from "../../hooks/useAuth";

const DashboardFilters = ({ setIdBrand, setIdContactType, setDateFrom, setDateUntil, setIdConsent }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const idBrandRef = useRef();
    const [idBrands, setIdBrands] = useState([]);
    
    const idContactTypeRef = useRef();
    const [idContactTypes, setIdContactTypes] = useState([]);
    
    const idConsentRef = useRef();
    const [idConsents, setIdConsents] = useState([]);
    
    const { auth } = useAuth();

    useEffect(() => {

        const fetchData = async () => {
          
          setIsLoading(true);
          
          try {
            
            let data;

            data = await getBrands({ auth });
            setIdBrands(data.results);

            data = await getContactTypes({ auth });
            setIdContactTypes(data.results);

            data = await getConsents({ auth });
            setIdConsents(data.results);
    
            setError(null);
    
          } catch (error) {
    
            setError(error);
    
          }
    
          setIsLoading(false);
    
        };
    
        fetchData();
        
    }, [auth]);

    const handleSubmit = (event) => {

        event.preventDefault();
        
        setIdBrand(event.target.idBrand.value);
        setIdConsent(event.target.idConsent.value);
        setIdContactType(event.target.idContactType.value);
        setDateFrom(event.target.dateFrom.value);
        setDateUntil(event.target.dateUntil.value);
        setDateUntil(event.target.dateUntil.value);

        if(event.target.dateFrom.value != '' && !validateDate(event.target.dateFrom.value)) {

            alert('La fecha desde ingresada no es válida.'+event.target.dateFrom.value);
            return false;
      
        }

        if(event.target.dateUntil.value != '' && !validateDate(event.target.dateUntil.value)) {

            alert('La fecha hasta ingresada no es válida.'+event.target.dateUntil.value);
            return false;
      
        }


    }
  


    return (
        <>

          <div className="filters_div">

          <form action="" onSubmit={handleSubmit}>

              <ul>

              <li>
                  <label htmlFor="idBrand">
                      <span>Marca</span>
                      <select 
                          ref={idBrandRef}
                          id="idBrand" 
                          name="idBrand" 
                      >
                          <option value="0">Todas</option>
                          
                          {!isLoading && idBrands && idBrands?.map((option, index) => (
                              <option key={index} value={option.id}>
                              {option.name}
                              </option>
                          ))}
                      
                      </select>
                  </label>
              </li>

              <li>
                  <label htmlFor="idContactType">
                      <span>Tipo de destinatario</span>
                      <select 
                          ref={idContactTypeRef}
                          id="idContactType" 
                          name="idContactType" 
                      >
                          <option value="0">Todos</option>
                          
                      {!isLoading && idContactTypes && idContactTypes?.map((option, index) => (
                          <option key={index} value={option.id}>
                          {option.name}
                          </option>
                      ))}
                      
                      </select>
                  </label>
              </li>

              <li>
                  <label htmlFor="dateFrom">
                  <span>Fecha Desde</span>
                  <input 
                      name="dateFrom" 
                      type="text"
                      placeholder="YYYY-MM-DD"    
                      className="w--110 center"
                  />
                  </label> 
              </li>

              <li>
                  <label htmlFor="dateUntil">
                  <span>Fecha Hasta</span>
                  <input 
                      name="dateUntil" 
                      type="text"
                      placeholder="YYYY-MM-DD"
                      className="w--110 center"
                  />
                  </label> 
              </li>

              <li>
                  <label htmlFor="idConsent">
                      <span>Consentimiento</span>
                      <select 
                          ref={idConsentRef}
                          id="idConsent" 
                          name="idConsent" 
                      >
                          <option value="0">Sin especificar</option>
                          
                      {!isLoading && idConsents && idConsents?.map((option, index) => (
                          <option key={index} value={option.id}>
                          {option.name}
                          </option>
                      ))}
                      
                      </select>
                  </label>
              </li>

              <li>
                  <button className="button-outline" type="submit">Buscar</button>
              </li>
              {/* <li>
                  <button className="button-outline" type="button">Descargar a excel</button>
              </li> */}
              </ul>

          </form>

          </div>


        </>
    )
}

export default DashboardFilters